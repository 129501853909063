import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarThree = _resolveComponent("NavbarThree")!
  const _component_ResponsiveNavbarThree = _resolveComponent("ResponsiveNavbarThree")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_YourGatewayToReliable = _resolveComponent("YourGatewayToReliable")!
  const _component_WhyChoiceForYou = _resolveComponent("WhyChoiceForYou")!
  const _component_WorkingFasterWithPowerfulTools = _resolveComponent("WorkingFasterWithPowerfulTools")!
  const _component_DownloadOurMobileApp = _resolveComponent("DownloadOurMobileApp")!
  const _component_JoinWithUs = _resolveComponent("JoinWithUs")!
  const _component_IndieBoosting = _resolveComponent("IndieBoosting")!
  const _component_FooterTwo = _resolveComponent("FooterTwo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavbarThree, { class: "home_seven" }),
    _createVNode(_component_ResponsiveNavbarThree, { class: "home_seven" }),
    _createVNode(_component_MainBanner),
    _createVNode(_component_YourGatewayToReliable),
    _createVNode(_component_WhyChoiceForYou),
    _createVNode(_component_WorkingFasterWithPowerfulTools),
    _createVNode(_component_DownloadOurMobileApp),
    _createVNode(_component_JoinWithUs),
    _createVNode(_component_IndieBoosting),
    _createVNode(_component_FooterTwo)
  ], 64))
}