<template>
  <div id="indieboosting-container" class="indieboosting home_seven-join-wrapper position-relative index-1">
    <div class="container indiebacklink-wrapper">
      <div class="w-full max-w-3xl mx-auto mb-10">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndieBoosting",
  mounted() {
    // Dynamically add the external script
    const script = document.createElement("script");
    script.src = "https://cdn.indieboosting.com/latest/script.js?id=H116BYOLQE&maxProducts=6";
    script.async = true;
    document.body.appendChild(script);
  }
};
</script>

<style lang="scss" scoped>
@import url("https://cdn.indieboosting.com/latest/style.css");

.home_seven-join-wrapper {
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 50%;
    background-color: var(--secondaryColor);
    z-index: -1;
  }

  .join-box {
    padding: 0 85px 0 85px;

    .join-content {
      padding: 115px 0;
    }

    .join-bg-shape {
      top: 0;
      left: 0;
      height: 100%;
    }

    .join-shape-two {
      bottom: 65px;
      right: 28px;
    }

    .join-img-wrap {
      height: 100%;

      .join-img-shape {
        bottom: 5%;
        right: 20%;
        z-index: 0;
      }

      .join-img {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        margin: 0 auto 0 0;
        filter: drop-shadow(0px 17px 67px rgba(6, 12, 69, 0.44));
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_seven-join-wrapper {
    .join-box {
      padding: 55px 20px 55px 20px;

      .join-img-wrap {
        margin-bottom: 30px;

        .join-img {
          position: relative;
          bottom: auto;
          left: auto;
          margin: 0 auto;
        }
      }

      .join-content {
        padding: 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_seven-join-wrapper {
    .join-box {
      padding: 70px 35px 70px 35px;

      .join-img-wrap {
        margin-bottom: 30px;

        .join-img {
          position: relative;
          bottom: auto;
          left: auto;
          margin: 0 auto;
        }
      }

      .join-content {
        padding: 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_seven-join-wrapper {
    .join-box {
      padding: 30px 30px 0;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home_seven-join-wrapper {
    .join-box {
      padding-top: 20px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_seven-join-wrapper {
    .join-box {
      .join-content {
        margin-left: -25px;
      }
    }
  }
}
</style>